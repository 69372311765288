.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px; /* लेबल और इनपुट के बीच गैप */
  margin-bottom: 10px;
}

.input-group label {
  min-width: 80px; /* लेबल की फिक्स चौड़ाई */
  text-align: left;
  font-weight: bold;
}

.input-group input {
  flex: 1; /* पूरा स्पेस लें */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.follow-us-container {
  padding-top: 15px;
}

.follow-us-title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.follow-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.follow-link {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: black;
  transition: color 0.3s ease-in-out;
}

.follow-link:hover {
  color: #555;
}

.play-icon {
  font-size: 28px;
  color: #34a853;
}
